import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
// import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import DatePicker from "react-datepicker";
import "react-data-table-component-extensions/dist/index.css";
import axios from "axios";
import {  Link, useNavigate } from 'react-router-dom'
import Dashboard_banner from "./Dashboard_banner";
import "react-datepicker/dist/react-datepicker.css";
import filterdrop from '../../../images/filterdrop-icon.png'
import loading from '../../../images/spin.gif'
import { Helmet } from "react-helmet";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export default function Domestic_orders() {
    const accountno = sessionStorage.getItem('accountno')
    const API_PATH = `${SERVER_URL}orders.php?type=domestic`; 
    const [orders, setorders] = useState('')
    const [ordercount, setordercount] = useState(0)
    const [data, setdata2] = useState([])
    const [isload, setisload] = useState(true)
    useEffect(() => {
 
        axios({
          method: 'post',
          url: `${API_PATH}`,
          data: {accountno:accountno}
        })
          .then((res) => {
            setisload(false)
           
            //setordercount(res.data.orders.length)
            setordercount(res.data.length)
        
            setdata2(res.data)
        // console.log(JSON.parse(JSON.stringify(res.data)))
         setorders(JSON.parse(JSON.stringify(res.data)))
            //setoutfordelivery(JSON.parse(res.data.outfordelivery))
           // setpoddelivery(JSON.parse(res.data.podorders))
           // setorderreport(JSON.parse(JSON.stringify(res.data)))
          }
          )
          .catch((err) => console.log(err))
    
      }, [])

 
     
      function getTracking(val){
        window.open('/track/'+val, '_blank', 'noopener,noreferrer');
      
      // navigate('/track/'+val)
      }
  
    const columns = [
       
        {
          name: "AWB",
          selector: "AWBNo",
          sortable: true,
          cell: (d) => (
            <a onClick={()=>getTracking(d.AWBNo)} target="_blank" className="dlink">
              {d.AWBNo}
            </a>
          )
        },
        {
            name: "ShipperRef",
            selector: "ShipperRef",
            sortable: true
          }
          ,
        {
          name: "Consignee",
          selector: "Consignee",
          sortable: true
        },
        {
          name: "Weight (kg)",
          selector: "Weight",
          sortable: true
        },
        {
            name: "From",
            selector: "Origin",
            sortable: true
          },
          {
            name: "To",
            selector: "Destination",
            sortable: true
          },
        {
          name: "Pickup Date",
          selector: "PickupDate",
          sortable: true,
        },
        {
            name: "Last Updated",
            selector: "LastUpdatedDate",
            sortable: true,
            
          },
        {
            name: "Remarks",
            selector: "Remarks",
            sortable: true, 
            width: '20%',
            cell: row => <div style={{fontSize: 12}}>{row.Remarks}</div>
          }
      ];
      const tableData = {
        columns,
        data
      };


      const [filtersec, setfiltersec] = useState(false)
const date=new Date();
const date2=new Date();
   
let starts = date.setDate(date.getDate() - 7);
let enddate = date2.setDate(date2.getDate() - 1);

const [startDate, setStartDate] = useState(starts);
const [endDate, setendDate] = useState(enddate);
console.log(startDate)
function enablefiltersec(){
if(filtersec==true){
  setfiltersec(false)
}else{
  setfiltersec(true)
}
}

function changedate(date){
  setStartDate(date)
}
function changedate2(date){
  setendDate(date)
}

function submitfilter(){
  setisload(true)
  axios({
    method: 'post',
    url: `${API_PATH}`,
    data: {accountno:accountno,startDate:startDate,endDate:endDate}
  })
    .then((res) => {
      console.log(res.data)
      setisload(false)
      setfiltersec(false)
      
      console.log(res.data)
      //setordercount(res.data.orders.length)
      setordercount(res.data.length)
  
      setdata2(res.data)
  // console.log(JSON.parse(JSON.stringify(res.data)))
   setorders(JSON.parse(JSON.stringify(res.data)))
      //setoutfordelivery(JSON.parse(res.data.outfordelivery))
     // setpoddelivery(JSON.parse(res.data.podorders))
     // setorderreport(JSON.parse(JSON.stringify(res.data)))
    }
    )
    .catch((err) => console.log(err))
}

     // console.log(orders['orders'])
  return (
    <div>
      <Helmet>
        <title>Time Express | Domestic Orders</title>
      </Helmet>
         <Dashboard_banner/>
    <div className="container">
    <div className="row" style={{ padding: "4%",paddingBottom:"0px" }}>
      
    <div className="filtbox" style={{marginBottom:"9%"}}>
          <p onClick={enablefiltersec}><img src={filterdrop} style={{background:" #8f44ad",padding:"4px"}}></img></p>
          {filtersec?(  
      
            <div className="row filtersec">
              <div className="date-holder">
          <DatePicker className="form-control shipping_date" name="shipping_date" dateFormat='dd/MM/yyyy' selected={startDate} onChange={date => {changedate(date);}} />
          </div>
          <div className="date-holder">
          <DatePicker className="form-control shipping_date" name="shipping_date" dateFormat='dd/MM/yyyy' selected={endDate} onChange={date => {changedate2(date);}} />
          </div>
          <button className="btn filterbtn" style={{background:"#fff"}} onClick={submitfilter}>Submit</button>
         {
          isload?(
<p style={{textAlign:"center"}}><img src={loading} alt="" style={{width:"3%"}} /></p>
          ):null
         } 
            </div>
            

          ):null}
        
          </div> 
          
          </div> 
       

<div className="main">

{isload?(
  <div style={{textAlign:"center"}}>
  <p style={{textAlign:"center"}}>Please wait. The data is loading ! </p>
  <img src={loading} alt="" style={{width:"4%"}}  />
  </div>
):(<DataTableExtensions {...tableData}>
  <DataTable
    columns={columns}
    data={data}
    noHeader
    defaultSortField="id"
    // sortIcon={<SortIcon />}
    defaultSortAsc={true}
    pagination
    highlightOnHover
    dense
  />
</DataTableExtensions>
)}

    
  </div>
    </div>
    </div>
    
  )
}
